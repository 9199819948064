jQuery(document).ready(function($) {
	$('.two-by-slider-wrapper .two-by-slider-slider-wrapper:not(.one-slide)').slick({
		dots: true,
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 2,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4000,
		responsive: [
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		]
	});
}); /* end of as page load scripts */